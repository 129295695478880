




























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import { msToTimeStr, timeStrToMS } from '@/util';
import Tooltip from '@/components/Dashboard/Tooltip.vue';
import { rootModule } from '@/store';
import { apiGETMulti, apiModify } from '@/api';
import { Socket } from 'vue-socket.io-extended';

@Component({
  components: {
    Tooltip,
  },
})
export default class extends Vue {
  runAttempts: API.RunAttempts.GET[] = [];
  finishTimeTxt = '00:00:00';
  dialogProp = false;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    // Reload relevant API data if event happens to change, or on load.
    this.loadAPIData();
  }

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    if (this.selectedEvent) {
      Vue.set(this, 'runAttempts', (await apiGETMulti('runAttempts', {
        offset: 0,
        embed: ['user'],
        eventId: this.selectedEvent,
        verified: false,
      }, true)).data);
    }
  }

  @Socket('runAttemptModified')
  scktAttmptModified(newVal: API.RunAttempts.GET | null, oldVal: API.RunAttempts.GET | null): void {
    const index = this.runAttempts.findIndex((i) => i.id === oldVal?.id);
    if (index >= 0 && newVal && oldVal) {
      if (newVal.verified && !oldVal.verified) {
        this.runAttempts.splice(index, 1);
      } else {
        Vue.set(this.runAttempts, index, { ...this.runAttempts[index], ...newVal });
      }
    } else if (newVal && !newVal.verified) {
      this.loadAPIData();
    } else if (!newVal && oldVal) {
      this.loadAPIData();
    }
  }

  // Updates the embedded users if currently in a loaded attempt entity.
  @Socket('userModified')
  sockerUserModified(newVal: API.Users.GET | null, oldVal: API.Users.GET | null): void {
    this.runAttempts.forEach((attempt, i) => {
      if (newVal && oldVal && attempt.userId === oldVal.id) {
        Vue.set(this.runAttempts[i], 'user', { ...this.runAttempts[i].user, ...newVal });
      }
    });
  }

  @Watch('dialogProp')
  onDialogChange(val: boolean): void {
    if (val && this.run?.finishTime) {
      this.finishTimeTxt = msToTimeStr(this.run.finishTime);
    }
  }

  isTimeStr(str?: string): boolean {
    return !!(str || this.finishTimeTxt).match(/^(\d+:)?(?:\d{1}|\d{2}):\d{2}$/g);
  }

  async saveTime(): Promise<void> {
    if (this.run) {
      await apiModify('runAttempts', this.run.id, { finishTime: timeStrToMS(this.finishTimeTxt) });
      this.finishTimeTxt = '00:00:00';
      this.dialogProp = false;
      const index = this.runAttempts.findIndex((i) => i.id === this.run?.id);
      if (index >= 0) this.runAttempts.splice(index, 1);
    }
  }

  cancelTime(): void {
    this.finishTimeTxt = '00:00:00';
    this.dialogProp = false;
  }

  async verify(): Promise<void> {
    if (this.run) {
      await apiModify('runAttempts', this.run.id, { verified: true });
      const index = this.runAttempts.findIndex((i) => i.id === this.run?.id);
      if (index >= 0) this.runAttempts.splice(index, 1);
    }
  }

  async ignore(): Promise<void> {
    if (this.run) {
      await apiModify('runAttempts', this.run.id, { verified: true, finishTime: null });
      const index = this.runAttempts.findIndex((i) => i.id === this.run?.id);
      if (index >= 0) this.runAttempts.splice(index, 1);
    }
  }

  keydown(evt: KeyboardEvent): void {
    // "keyCode" is deprecated but leaving here in case for now.
    if (evt.key === 'Enter' || evt.keyCode === 13 || evt.code === 'Enter') {
      this.saveTime();
    } else if (evt.key === 'Escape' || evt.keyCode === 27 || evt.code === 'Escape') {
      this.cancelTime();
    }
  }

  get run(): API.RunAttempts.GET | undefined {
    return this.runAttempts[0];
  }

  get name(): string | undefined {
    return this.run?.user?.name || '?';
  }

  get finishTime(): string | undefined {
    return this.run?.finishTime ? msToTimeStr(this.run.finishTime) : undefined;
  }
}
