










































import clone from 'clone';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import TabContent from './PlayerStats/TabContent.vue';
import { rootModule } from '@/store';
import { storeNS } from '../../store';
import { apiGETMulti } from '@/api';
import { Socket } from 'vue-socket.io-extended';

@Component({
  components: {
    TabContent,
  },
})
export default class extends Vue {
  @storeNS.State readonly players!: API.Players.GET[];
  playerData: API.PlayerData.GET[] = [];
  lbPlacements: API.LeaderboardPlacements.GET[] = [];
  tab = null;

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    // Reload relevant API data if event happens to change, or on load.
    this.loadPlayerDataAPIData();
    this.loadLBPlacementsAPIData();
  }

  async loadPlayerDataAPIData(): Promise<void> {
    if (this.selectedEvent) {
      Vue.set(this, 'playerData', ((await apiGETMulti('playerData', {
        eventId: this.selectedEvent,
      }, true)).data));
    }
  }

  async loadLBPlacementsAPIData(): Promise<void> {
    if (this.selectedEvent) {
      Vue.set(this, 'lbPlacements', ((await apiGETMulti('leaderboardPlacements', {
        eventId: this.selectedEvent,
      }, true)).data));
    }
  }

  @Socket('playerDataModified')
  socketPlayerDataModified(
    newVal: API.PlayerData.GET | null,
    oldVal: API.PlayerData.GET | null,
  ): void {
    const index = this.playerData.findIndex((d) => d.playerId === oldVal?.playerId);
    if (index >= 0) {
      if (newVal) Vue.set(this.playerData, index, { ...this.playerData[index], ...newVal });
      else this.playerData.splice(index, 1);
    } else if (newVal && newVal.eventId === rootModule.selectedEvent) {
      this.playerData.unshift(newVal);
    }
  }

  @Socket('leaderboardPlacementModified')
  socketLBPlacementModified(
    newVal: API.LeaderboardPlacements.GET | null,
    oldVal: API.LeaderboardPlacements.GET | null,
  ): void {
    const index = this.lbPlacements.findIndex((p) => p.playerId === oldVal?.playerId);
    if (index >= 0) {
      if (newVal) {
        Vue.set(this.lbPlacements, index, {
          ...this.lbPlacements[index], ...newVal,
        });
      } else this.lbPlacements.splice(index, 1);
    } else if (newVal && newVal.eventId === rootModule.selectedEvent) {
      this.lbPlacements.unshift(newVal);
    }
  }

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get playersSorted(): API.Players.GET[] {
    return clone(this.players)
      .sort((a, b) => (a.user?.name || '?')
        .localeCompare(b.user?.name || '?', 'en', { sensitivity: 'base' }));
  }
}
