





















import { Vue, Component } from 'vue-property-decorator';
import CasterLeaderboard from '@/components/Dashboard/CasterLeaderboard.vue';
import PlayerStats from './components/PlayerStats.vue';
import RunVerification from './components/RunVerification.vue';
import EventFeed from './components/EventFeed.vue';

@Component({
  components: {
    PlayerStats,
    CasterLeaderboard,
    RunVerification,
    EventFeed,
  },
})
export default class extends Vue {}
