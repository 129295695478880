
























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { API } from '@/types';
import { deltaMSToTimeStr, msToTimeStr } from '@/util';

@Component
export default class extends Vue {
  @Prop({ type: Object, required: true }) readonly playerData!: API.PlayerData.GET;
  options = {
    page: 1,
    itemsPerPage: 10,
  };
  headers = [
    { text: '#', value: 'index', filterable: false, align: 'end' },
    { text: 'Name', value: 'name' },
    { text: 'Time', value: 'attemptTime', filterable: false },
    { text: 'Length', value: 'attemptLength', filterable: false },
    { text: 'Delta', filterable: false },
    { text: 'PB Time', value: 'pbTime', filterable: false },
    { text: 'PB Length', value: 'pbLength', filterable: false },
    { text: 'Best Length', value: 'bestLength', filterable: false },
  ];
  search = '';
  msToTimeStr = msToTimeStr;
  deltaMSToTimeStr = deltaMSToTimeStr;

  @Watch('playerData.splitIndex', { immediate: true })
  onSplitChanged(val: number | null): void {
    if (typeof val === 'number') {
      this.options.page = Math.ceil((val + 1) / this.options.itemsPerPage);
    }
  }

  updateOptions(val: DataOptions): void {
    this.options = val;
  }
}
