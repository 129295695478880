
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import Chart from 'chart.js';
import { Doughnut, mixins } from 'vue-chartjs';
import { API } from '@/types';

Chart.defaults.global.defaultFontColor = 'white';

@Component({
  mixins: [
    Doughnut,
    mixins.reactiveData,
  ],
})
export default class extends Mixins(Doughnut) {
  @Prop({ type: Object, required: true }) readonly data!: API.PlayerData.GET;
  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions = {
    legend: {
      position: 'bottom',
    },
  };
  colours: { [k: string]: string } = {};

  // TODO: Find a good selection of colours?
  palette = [
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(0, 163, 51)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
    'rgb(0, 0, 255)',
  ];

  generateColour(index: number): string {
    return this.palette[index % this.palette.length];
  }

  updateChartData(): void {
    if (this.data.segments) {
      const segmentsWithResets = this.data.segments.filter((s) => s.resetCount > 0);
      this.chartData = {
        labels: segmentsWithResets.map((s) => s.name),
        datasets: [
          {
            label: 'Number of Resets',
            backgroundColor: segmentsWithResets.map((s, i) => {
              if (!this.colours[s.index]) {
                this.colours[s.index] = this.generateColour(i);
              }
              return this.colours[s.index];
            }),
            data: segmentsWithResets.map((s) => s.resetCount),
          },
        ],
      };
    } else {
      this.chartData = null;
    }
  }

  @Watch('data')
  onDataChange(newVal: API.PlayerData.GET, oldVal?: API.PlayerData.GET): void {
    if (!oldVal
    || newVal.attemptedRunsCount !== oldVal.attemptedRunsCount
    || newVal.time < oldVal.time) {
      this.updateChartData();
    }
  }

  mounted(): void {
    this.updateChartData();
    this.renderChart(this.chartData || { labels: [], datasets: [] }, this.options);
  }
}
