



















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Tooltip from '@/components/Dashboard/Tooltip.vue';
import { API } from '@/types';
import { rootModule } from '@/store';
import { apiModify, axiosConfig } from '@/api';
import axios from 'axios';

dayjs.extend(relativeTime);

@Component({
  components: {
    Tooltip,
  },
})
export default class extends Vue {
  @Prop({ type: Object, required: true }) readonly entry!: API.EventLog.GET;
  @Prop(Boolean) readonly caster!: boolean;
  // @videosNS.State videoPlayerData!: API.Videos.VPlayerData.GET[];
  dayjs = dayjs;
  fromNow = '?';
  clipDialog = false;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  /* get playerData(): API.Videos.VPlayerData.GET['value'] {
    return clone(
      this.videoPlayerData.find((d) => d.eventId === this.selectedEvent)?.value
      || { playing: false, video: null },
    );
  } */

  get playerName(): string {
    if (this.entry.fromTech) {
      return 'Tech Crew';
    }
    return this.entry.user?.name || '?';
  }

  get cardMsg(): string {
    return this.entry.card?.msg || '?';
  }

  get twitchClipUrl(): string {
    return 'https://clips.twitch.tv/embed'
      + `?clip=${this.entry.clipTwitchId}&parent=${window.location.hostname}`
      + '&muted=true&autoplay=true';
  }

  async clear(): Promise<void> {
    await apiModify('eventLog', this.entry.id, { cleared: true });
    this.$emit('eventLogClear', this.entry.id);
  }

  created(): void {
    this.fromNow = dayjs(this.entry.creationTimestamp).fromNow();
    window.setInterval(() => {
      this.fromNow = dayjs(this.entry.creationTimestamp).fromNow();
    }, 1000);
  }

  async showVideoPlayer(): Promise<void> {
    if (this.selectedEvent) {
      await axios.post(
        `/nodecg/show_videoplayer/${this.selectedEvent}`,
        { entryId: this.entry.id },
        axiosConfig(),
      );
    }
  }
}
