









































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import { deltaMSToTimeStr, getOrdinal, msToTimeStr } from '@/util';
import InfoCard from './InfoCard.vue';
import countryData from './CountryData';
import ResetChart from './ResetChart.vue';
import SplitTable from './SplitTable.vue';
import { rootModule } from '@/store';
import { storeModule, storeNS } from '@/views/Dashboard/store';
import { apiModify } from '@/api';

@Component({
  components: {
    InfoCard,
    ResetChart,
    SplitTable,
  },
})
export default class extends Vue {
  @Prop({ type: Object, required: true }) readonly player!: API.Players.GET;
  @Prop({ type: Array, required: true }) readonly playerDataArr!: API.PlayerData.GET[];
  @Prop({ type: Array, required: true }) readonly lbPlacements!: API.LeaderboardPlacements.GET[];
  @storeNS.State readonly event!: API.Events.GET | null;
  msToTimeStr = msToTimeStr;
  deltaMSToTimeStr = deltaMSToTimeStr;
  getOrdinal = getOrdinal;
  currentTime = Date.now();
  active = this.player.active || false;
  activeChanging = false;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Watch('player.active')
  onPlayerActiveChange(val: boolean): void {
    this.active = val;
  }

  async changeActive(state: boolean): Promise<void> {
    try {
      this.activeChanging = true;
      const { data } = await apiModify('players', this.player.id, { active: state });
      storeModule.updateStoredPlayer(data);
    } catch (err) {
      this.active = this.player.active;
    }
    this.activeChanging = false;
  }

  created(): void {
    window.setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
  }

  get baseURL(): string {
    return process.env.BASE_URL as string;
  }

  get playerData(): API.PlayerData.GET | undefined {
    return this.playerDataArr.find((d) => d.playerId === this.player.id);
  }

  get segmentsWithResets(): number {
    return this.playerData?.segments.filter((s) => s.resetCount > 0).length ?? 0;
  }

  get customTitle(): string | { [k: string]: unknown } | undefined {
    if (!this.player.customTitle) {
      return undefined;
    }
    let json;
    try {
      json = JSON.parse(this.player.customTitle);
    } catch (err) { /* error */ }
    return json || this.player.customTitle;
  }

  get personalBest(): { time: number; source: 'static' | 'livesplit' } {
    const pbStatic = this.player.pb;
    const pbLiveSplit = this.playerData?.personalBest;
    return (pbLiveSplit && pbLiveSplit < pbStatic)
      ? { time: pbLiveSplit, source: 'livesplit' } : { time: pbStatic, source: 'static' };
  }

  get averageSplitLength(): number {
    const segments = (this.playerData?.segments ?? []).filter((s) => s.attemptLength !== null);
    return segments.reduce((prev, curr) => (curr.attemptLength ?? 0) + prev, 0) / segments.length;
  }

  get lbTime(): API.LeaderboardPlacements.GET | undefined {
    const placement = this.lbPlacements.find((p) => p.playerId === this.player.id);
    if (!placement) {
      return undefined;
    }
    return placement;
  }

  get countryName(): string {
    return countryData.find((c) => c.code === this.player.user?.country)?.name || '?';
  }

  get isLivesplitConnNotActive(): boolean {
    if (this.playerData?.livesplitLastMsgReceived) {
      return this.currentTime
        - new Date(this.playerData.livesplitLastMsgReceived).getTime() > 60 * 1000;
    }
    return true;
  }
}
